/* The body background should be set in the main stylesheet of your application */
.service-card {
    display: flex;
    flex-direction: column;
    transition: transform 0.2s;
    height: 100%;
  }
  
  .service-card .card-body {
    flex: 1; /* Flex-grow to make the card-body fill the space */
  }
  
  /* Ensure that images do not scale out of their containers */
  .card-img-top.img-fluid {
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  /* Keep the scale transform small to prevent layout shifts */
  .service-card:hover {
    transform: scale(1.02);
  }
  
  /* This helps to keep the footer at the bottom of the card */
  .service-card .card-body {
    display: flex;
    flex-direction: column;
  }
  
  .card-body {
    padding: 1rem;
    flex-grow: 1; /* Makes the body grow to fill space, pushing down the footer */
  }
  
  .card-title {
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 767px) {
    .service-card {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* This will create a consistent space between cards */
    justify-content: center; /* This will center the cards in the middle of the page */
  }
  
  /* Assuming .col-* is your card container */
  .col-12.col-sm-6.col-lg-4 {
    display: flex;
    flex-basis: calc(33% - 20px); /* Subtract the gap from the width */
    margin-bottom: 20px; /* For vertical spacing */
  }
  
  /* Set a consistent height and object-fit for all images */
  .card-img-top.img-fluid {
    height: 280px; /* Adjust as necessary */
    object-fit: fill;
    width: 100%; /* This will make sure the image covers the full card width */
  }
  