.question-list {
  padding: 10px;
  border-top: 1px solid #ddd;
}

.question-list button {
  color: #007bff;
  text-align: left;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
  display: block;
}

.message.question {
  background-color: #d1eaff; /* Updated color for better distinction */
  color: #0056b3; /* Color adjusted for readability on the new background */
  cursor: pointer;
  border-left: 3px solid #0056b3; /* Updated border color to match the text */
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 18px;
}

.message.question:hover {
  background-color: #b0d4ff; /* A slightly darker hover effect for better interaction */
}

.chatbot-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  max-width: 350px; 
  width: 100%; 
  max-height: 90%; 
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

.message-list {
  overflow-y: auto;
  padding: 10px;
  flex-grow: 1;
}

.message {
  list-style-type: none;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px; /* More pronounced roundness */
  max-width: 75%;
}

.message.user {
  background-color: #5b7db1;
  color: white;
  margin-left: auto;
  text-align: right;
  word-wrap: break-word;
}

.message.bot {
  background-color: #e1e1e1;
  text-align: left;
  word-wrap: break-word;
}

.chat-toggle-btn {
  background-color: darkblue;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.predefined-questions {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.question-button {
  margin: 5px 0;
  border-radius: 20px;
  color: #5b7db1;
  border: 1px solid #5b7db1;
  padding: 5px 15px;
  transition: background-color 0.2s, color 0.2s;
  white-space: normal; /* Allows text within the button to wrap */
  text-align: left; /* Aligns text to the left */
}

.question-button:hover {
  background-color: #5b7db1;
  color: white;
}

.chat-interface {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 100px);
  background-color: #ffffff;
  border-radius: 18px; /* This applies to all corners, we'll need to change that */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding: 0; /* Ensure no padding is affecting the width */
}

.chat-header {
  background-color: #007bff;
  color: white;
  padding: 12px 15px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  width: 100%; /* Adjust width for padding, assuming 15px padding on each side */
}

/* Apply border-radius to individual corners */
.chat-interface {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-left-radius: 0; /* No border-radius on bottom corners */
  border-bottom-right-radius: 0; /* No border-radius on bottom corners */
}


.close-icon {
  cursor: pointer;
  align-self: start; /* Aligns the icon to the top */
  padding: 2px; /* Makes it easier to click */
  margin: -2px; /* Adjust margin to counteract padding and align the icon */
  color: white;
  font-size: 24px;
}

/* Ensure buttons are styled appropriately */
.question-button {
  /* ... other styles ... */
  margin-bottom: 10px; /* Add bottom margin for spacing */
  display: inline-block; /* Aligns buttons to behave like inline elements */
}

/* Add a hover effect for the buttons */
.question-button:hover {
  background-color: #0056b3;
  color: white;
  border-color: #0056b3;
}


@media (max-width: 400px) {
  .chatbot-container {
    right: 10px;
    bottom: 10px;
    max-width: calc(100% - 20px); /* Subtracts margin from the total width */
  }

  .chat-interface {
    max-height: calc(100vh - 20px); /* Adjusts height on very small screens */
  }

  /* .chat-header, .close-icon {
    padding: 8px 10px; 
  } */

  .message-list {
    padding: 5px; /* Smaller padding within the message list */
  }

  .chat-header {
    padding: 0.5rem; /* Even smaller padding on very small screens */
  }

}



/* Additional styles to ensure the text "Chat with us" is aligned */
.chat-header span {
  padding-left: 18px; /* Adjust this value to align the text with the left edge */
  flex-grow: 1; /* Allows the text to take up the available space */
}
