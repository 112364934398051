/* Home.css */
.Home {
    text-align: center;
    margin:10px;
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 720px; /* Adjust height as needed */
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }
  
  .content-box-transparent {
    position: absolute;
    top: 40%;
    left: calc(80% + 50px); /* Move 20px to the right of the center */
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.75); /* Transparent background */
    width: 90%; /* Set width to 90% for smaller screens */
    max-width: 400px; /* Limit maximum width */
  }
  
  /* Styling for the content inside the content box */
  .content-box-transparent h1 {
    margin-bottom: 10px;
  }
  
  .content-box-transparent p {
    margin: 0 0 10px;
  }
  
  .content-box-transparent button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* Styling for the container */
  .container {
    margin-top: 20px;
  }
  
  /* Styling for the header */
  .container h2 {
    margin-bottom: 20px;
  }

  .wrapper {
    overflow-x: hidden; /* Change to hidden to hide the overflow */
    width: 100%; /* Change to 100% to fit the container */
    white-space: nowrap;
    display: inline-block; /* Change to inline-block */
  }
  
  .insurance-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none; /* Remove list dots */
    animation: scroll 30s linear infinite;
    width: max-content;
  }
  
  .insurance-list li {
    margin-right: 20px; /* Adjust as needed */
  }
  
  .insurance-list li:last-child {
    margin-right: 0;
  }
  
  .insurance-list img {
    max-width: 120px; /* Limit the maximum width */
    max-height: 100%; /* Maintain aspect ratio */
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
   100% {
      transform: translateX(calc(-100% - 20px)); /* Adjusted to move one image completely off-screen including margin */
    }
  }

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .content-box-transparent {
      width: 90%; /* Set width to 90% for smaller screens */
      max-width: 90%; /* Limit maximum width */
      left: 50%; /* Center horizontally */
      transform: translate(-50%, -50%);
      padding: 15px; /* Adjust padding */
    }
  }
 

  .service-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .service-icon {
  width: 150px; /* Fixed width for consistent size */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center align both icon and text */
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  }
  
  .service-icon img {
    width: 90%; /* Adjust size as needed */
    height: 71px; /* Adjust size as needed */
   margin-bottom: 5px;
  }
  
  .service-icon span {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
  }


/* For mobile */
@media (max-width: 768px) {
  .service-icons {
    justify-content: center;
    flex-wrap: wrap; /* Allow icons to wrap to the next line */
  }

  .service-icon {
    flex-basis: calc(50% - 20px); /* Adjust width for two icons in a row */
    margin: 10px; /* Add spacing between icons */
  }
}

  @media (max-width: 480px) {
    .service-icon {
      flex-basis: calc(100% - 20px); /* Adjust width for one icon in a row */
    }
  }