/* Header.css - Enhanced styles for your React Bootstrap Header component */

html, body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevents horizontal scroll caused by full-width elements */
}

.headerText {
    color: #ffffff !important;
    font-size: 24px; /* Adjusted font size down from 50px */
    padding-left: 1rem; /* Adjust padding if needed */
    margin-right: auto; /* Pushes the navbar items to the right */
}

.userQuote{
    color: #ffffff !important;
    font-size: 24px; /* Adjusted font size down from 50px */
    padding-left: 1rem; /* Adjust padding if needed */
    margin-right: auto; /* Pushes the navbar items to the right */
}

/* General navbar styling */
.navbar {
    background-color: rgb(0, 161, 224);
    padding: 0.5rem 1rem;
    /* margin: 0; */
    width: 100vw; /* Sets width to full viewport width */
    box-sizing: border-box; /* Includes padding in the element's total width */
    margin: 0 auto; /* Center the navbar */
}

/* Logo styling */
.navbar-brand img {
    height: 80px;
    width: auto;
}

/* Navbar toggler icon customization */
.navbar-dark .navbar-toggler {
    border-color: rgba(255,255,255,0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Nav items and links styling */
.nav-link {
    color: #ffffff !important; /* Brightened nav link color for better contrast */
}

.nav-link:hover,
.nav-link:focus {
    color: black !important; /* Gold color for hover and focus, adding vibrancy */
}

/* Adjustments for smaller screens */
@media (max-width: 992px) {
    .navbar-nav {
        position: static;
        float: none;
    }
}



