.contact-container {
    background-color: #f5f5f7; /* Light grey background */
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; /* Adjust content distribution */
    font-family: 'Arial', sans-serif; /* Use a common sans-serif font */
    gap: 2rem; /* Add gap for spacing between children */
    box-sizing: border-box;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2rem; /* Space between contact info and map */
  }
  
  .contact-item {
    background-color: #ffffff; /* White background for cards */
    border: 1px solid rgb(154,176,167); /* Light grey border */
    border-radius: 4px; /* Rounded corners for the cards */
    padding: 2rem; 
    margin: 0.5rem;
    flex: 1; /* Distribute space evenly */
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    color:black;
    justify-content: flex-start;
  }
  
  .contact-item img {
    width: 150px; /* Specific size for the icons */
    height: auto;
    margin-bottom: 1rem;
  }
  
  .contact-item h3 {
    color: #333;
    margin-bottom: 0.5rem;
    font-size: 1rem; /* Size for the title */
  }
  
  .contact-item p{
    color:black;
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  .contact-item a{
    margin-bottom: 1rem;
  }

  

  .map-section {
    width: 100%; /* Confirm the container takes the full width */
    display: flex;
    justify-content: center; /* Center the iframe if needed */
    align-items: center;
    padding: 0;
    margin: 0 auto; /* Centering the map container within its parent */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .map-section iframe {
    width: 100%;
    height: 500px; /* As specified, but ensure it respects container constraints */
    border: none; /* Assuming you want no border */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .message-form {
    background-color: #e8f0fe; /* Soft Blue */
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
  }
  
  .message-form form {
    display: grid;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    gap: 1rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #5f6368; /* Dark Grey Border for contrast */
    background-color: #ffffff; /* White Background for clarity */
    color: #5f6368; /* Dark Grey Text for readability */
    border-radius: 4px;
  }
  
  .submit-btn {
    background-color: #1a73e8; /* Vibrant Blue */
    color: #ffffff; /* White Text for visibility */
    padding: 0.5rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #1669d9; /* Slightly Darker Blue for interactive feedback */
  }
  
  
  
  @media (max-width: 768px) {
    .contact-container {
      gap: 1rem; /* Less gap on smaller screens */
    }
    .contact-item {
        padding: 1rem; /* Reduce padding on smaller devices */
      }
      .submit-btn {
        padding: 0.5rem 1rem; /* Adjust button size for smaller screens */
      }
  }
