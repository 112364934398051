 /* Footer general styling */
footer {
  background-color: rgb(0, 161, 224); /* Dark shade, adjust as per your theme */
  color: white;
  padding-top: 10px; /* Reduced padding from 20px to 10px */
  padding-bottom: 10px; /* Add padding-bottom for balance, if necessary */
}

footer a.text-white {
  color: white;
}

footer a.text-white:hover {
  color: #ddd; /* Lighter shade for hover state */
}

/* Newsletter form styling */
.form-inline {
  justify-content: center;
}

.form-control {
  margin-right: 10px; /* Ensure some spacing between input and button */
}

.btn-primary {
  background-color: #007bff; /* Bootstrap primary color, adjust if needed */
}

/* Social icons styling */
.fa-facebook-f, .fa-twitter, .fa-instagram, .fa-linkedin {
  color: white; /* Icon color */
}

.fa-facebook-f:hover, .fa-twitter:hover, .fa-instagram:hover, .fa-linkedin:hover {
  color: #007bff; /* Color on hover, adjust as per your theme */
}

/* Padding adjustments */
.p-4 {
  padding: 1.5rem !important; /* Override Bootstrap if necessary */
}

/* Text and background color adjustments */
.bgtxtcolor, .bgtxtcolor h5 {
  background-color: rgb(0, 161, 224); /* Ensuring consistency */
  color: white;
}

/* Utility classes for additional adjustments */
.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-md-0 {
  margin-bottom: 0 !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
   .newsletter-form, .social-links {
    margin-bottom: 20px;
  }
}

/* Adjust overall footer padding */


/* Adjust margins for tighter layout */
.mb-4, .mb-md-0, .mr-2 {
  margin-bottom: 1rem !important; /* Reduce margin-bottom */
}

/* Adjust list and paragraph spacing */
footer ul, footer p {
  margin-bottom: 0.5rem; /* Reduce space between list items and paragraphs */
}

/* Ensure social links and newsletter form are not excessively spaced */
.d-flex, .form-inline {
  gap: 10px; /* Adjust spacing between items */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .newsletter-form, .social-links {
    margin-bottom: 15px;
  }
}



/* Contact and Fax Numbers styling */
footer .contact-information {
  position: relative;
  margin: 10px;
  text-align: center;
}

footer .contact-information p {
  margin-bottom: 10px; /* Adjust margin-bottom for separation between phone and fax numbers */
}



/* Mobile Responsive */
@media (max-width: 768px) {
  footer .contact-information {
    margin-top: 20px; /* Adjust margin-top for smaller screens */
    justify-content: flex-start; /* Align content to the start on smaller screens */
    flex-direction: column; /* Stack elements vertically */
    margin-right: 0; /* Remove negative margin for smaller screens */
    text-align: center;
  }

  footer .contact-information p {
    margin-bottom: 5px; /* Adjust margin-bottom for smaller screens */
  }
}
